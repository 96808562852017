import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";

import HomeView from "@/views/HomeView.vue";
import ProjectRequestView from "@/views/ProjectRequestView.vue";
const HEADER_OFFSET = 60; // Adjust this value based on your header height

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: HomeView,
    },
    {
      path: "/request",
      name: "Request",
      component: ProjectRequestView,
    },
  ],
  scrollBehavior(to, from) {
    if (to.hash) {
      return new Promise((resolve) => {
        nextTick(() => {
          setTimeout(() => {
            const element = document.querySelector(to.hash);
            if (element) {
              const rect = element.getBoundingClientRect();
              const top = rect.top + window.pageYOffset - HEADER_OFFSET;
              window.scrollTo({
                top,
                behavior: "smooth",
              });
            } else {
              console.warn(`Element ${to.hash} not found`);
            }
            resolve();
          }, 400); // Adjust delay as needed
        });
      });
    } else {
      return { top: 0 };
    }
  },
});

export default router;
