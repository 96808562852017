<template>
  <nav class="navbar sticky-top navbar-expand-lg bg-dark navbar-dark">
    <div class="container">
      <router-link to="/" class="nav-link active"
        ><img
          :src="host + '/img/logo.png'"
          alt="Logo"
          width="50"
          height="50"
          style="filter: invert()"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/#service" class="nav-link active"
              >Servicios</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/#project" class="nav-link active"
              >Proyectos</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/#tech" class="nav-link active"
              >Tecnologías</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/#contact" class="nav-link active"
              >Contactame</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      host: this.$host,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
