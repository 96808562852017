<template>
  <footer
    class="bg-dark d-flex flex-wrap justify-content-center align-items-center py-5 text-secondary"
  >
    <ul class="nav">
      <li class="nav-item">
        <a
          class="nav-link text-secondary"
          href="https://www.rozosmedia.com/"
          target="_Blank"
          >© 2024 , RozosMedia.com</a
        >
      </li>
    </ul>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
