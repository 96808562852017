<template>
  <div class="bg-dark px-4 py-5">
    <div class="row justify-content-center py-5">
      <div
        class="col-lg-10 text-white mb-5 wow animate__animated animate__slideInLeft"
      >
        <h1 class="display-4 fw-bold lh-1 mb-3">Requerimientos</h1>
        <p class="lead">
          Formulario de Requerimientos para Desarrollo de Sitio Web
        </p>
      </div>
      <div class="col-lg-10 text-white">
        <!-- Client Information Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center py-2 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Información del Cliente</h3>

          <div class="row" v-if="sections.clientInformation">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="mb-3">
                <label for="client_name" class="form-label required"
                  >Nombre</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="client_name"
                />
                <div>
                  <small class="text-danger">{{ error_client_name }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="mb-3">
                <label for="contact_person" class="form-label required"
                  >Persona de contacto</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="contact_person"
                />
                <div>
                  <small class="text-danger">{{ error_contact_person }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="mb-3">
                <label for="email" class="form-label required">Email</label>
                <input
                  type="email"
                  class="form-control custom-input"
                  v-model="email"
                  @input="validEmail"
                />
                <div>
                  <small class="text-danger">{{ error_email }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="mb-3">
                <label for="phone" class="form-label required">Celular</label>
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="phone"
                  @input="acceptNumber"
                />
                <div>
                  <small class="text-danger">{{ error_phone }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Project Details Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center py-2 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Detalles del proyecto</h3>
          <div class="row" v-if="sections.projectDetails">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="project_name" class="form-label required"
                  >Nombre del proyecto</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="project_name"
                />
                <div>
                  <small class="text-danger">{{ error_project_name }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="current_website" class="form-label"
                  >Sitio web actual</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="current_website"
                />
                <div>
                  <small class="text-danger">{{ error_current_website }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="project_description" class="form-label required"
                  >Descripción</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="project_description"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_project_description
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="project_objectives" class="form-label required"
                  >Objetivos</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="project_objectives"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_project_objectives
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Target Audience Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center py-2 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Audiencia</h3>
          <div class="row" v-if="sections.targetAudience">
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label for="target_audience_age" class="form-label required"
                  >Rango de edades</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="target_audience_age"
                />
                <div>
                  <small class="text-danger">{{
                    error_target_audience_age
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label for="target_audience_gender" class="form-label required"
                  >Genero</label
                >
                <select
                  class="form-select custom-input custom-select"
                  v-model="target_audience_gender"
                >
                  <option class="text-black" value="other">Otro</option>
                  <option class="text-black" value="male">Hombre</option>
                  <option class="text-black" value="female">Mujer</option>
                </select>
                <div>
                  <small class="text-danger">{{
                    error_target_audience_gender
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label
                  for="target_audience_location"
                  class="form-label required"
                  >Locación</label
                >
                <input
                  type="text"
                  class="form-control custom-input"
                  v-model="target_audience_location"
                />
                <div>
                  <small class="text-danger">{{
                    error_target_audience_location
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label
                  for="target_audience_interests"
                  class="form-label required"
                  >Interes</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="target_audience_interests"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_target_audience_interests
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Website Specifications Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center py-2 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Especificaciones del sitio</h3>
          <div class="row" v-if="sections.websiteSpecifications">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="website_sections" class="form-label required"
                  >Tipo</label
                >
                <select
                  class="form-select custom-input custom-select"
                  v-model="website_type"
                >
                  <option class="text-black" value="other">Informativo</option>
                  <option class="text-black" value="other">Portafolio</option>
                  <option class="text-black" value="other">Corporativo</option>
                  <option class="text-black" value="female">Blog</option>
                  <option class="text-black" value="male">E-comerce</option>
                </select>
                <div>
                  <small class="text-danger">{{ error_website_type }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="website_sections" class="form-label required"
                  >Número de páginas</label
                >
                <input
                  type="number"
                  class="form-control custom-input"
                  v-model="estimated_pages"
                />
                <div>
                  <small class="text-danger">{{ error_estimated_pages }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="website_sections" class="form-label required"
                  >Secciones</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="website_sections"
                  placeholder="Nosotros, Contacto, etc"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_website_sections
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="additional_features" class="form-label required"
                  >Características adicionales</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="additional_features"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_additional_features
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="design_preference" class="form-label required"
                  >Preferenica del diseño</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="design_preference"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_design_preference
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-3">
                <label for="design_preference" class="form-label required"
                  >Referencias de diseño</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="reference_websites"
                  rows="3"
                ></textarea>
                <div>
                  <small class="text-danger">{{
                    error_reference_websites
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Budget and Timeline Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center py-2 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Presupuesto y fechas</h3>
          <div class="row" v-if="sections.budgetTimeline">
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label for="budget" class="form-label required"
                  >Presupuesto</label
                >
                <input
                  type="number"
                  class="form-control custom-input"
                  v-model="budget"
                />
                <div>
                  <small class="text-danger">{{ error_budget }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label for="desired_start_date" class="form-label required"
                  >Fecha inicio</label
                >
                <input
                  type="date"
                  class="form-control custom-input"
                  v-model="desired_start_date"
                />
                <div>
                  <small class="text-danger">{{
                    error_desired_start_date
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div class="mb-3">
                <label for="desired_launch_date" class="form-label required"
                  >Fecha lanzamiento</label
                >
                <input
                  type="date"
                  class="form-control custom-input"
                  v-model="desired_launch_date"
                />
                <div>
                  <small class="text-danger">{{
                    error_desired_launch_date
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Additional Services Section -->
        <div
          class="row bs-gray-800 rounded justify-content-center pt-2 pb-4 mb-3 wow animate__animated animate__fadeIn"
        >
          <h3>Servicios adicionales</h3>
          <div class="row" v-if="sections.additionalServices">
            <!-- First Subsection -->
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="needs_seo"
                />
                <label> ¿Necesita SEO? </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="needs_digital_marketing"
                />
                <label> ¿Necesita marketing digital? </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="requires_maintenance"
                />
                <label> ¿Se necesita mantenimiento? </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  v-model="content_provided_by_client"
                />
                <label> ¿Contenido proporcionado? </label>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="additional_requirements" class="form-label"
                  >Requisitos adicionales</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="additional_requirements"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <!-- Second Subsection -->
            <!-- <div class="row mt-3">
              <div class="col-12 col-md-6 col-lg-6 mb-3">
                <label for="support_required" class="form-label"
                  >Soporte requerido</label
                >
                <textarea
                  class="form-control custom-input"
                  v-model="support_required"
                  rows="3"
                ></textarea>
              </div>
            </div> -->
          </div>
        </div>
        <div class="mb-3 wow animate__animated animate__fadeIn">
          <div class="row justify-content-center" v-if="loaderSave">
            <div class="col-lg-8 text-center">
              <span class="display-6"
                ><i class="fa-solid fa-spinner fa-spin"></i
              ></span>
            </div>
          </div>
          <div class="d-grid gap-2 col-12 col-md-6 col-lg-4 mx-auto" v-else>
            <button type="button" class="btn btn-light" @click="validForm()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Bootstrap Toast -->
    <div
      class="toast-container position-fixed top-0 end-0 p-3"
      style="z-index: 1100"
    >
      <div
        ref="toastElement"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">Bootstrap Toast</strong>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">Hello, this is a Bootstrap toast in Vue!</div>
      </div>
    </div>
  </div>
</template>
<script>
import apiClient from "@/axios.js";
import { useToast } from "vue-toastification";

export default {
  name: "ProjectRequestView",
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      sections: {
        clientInformation: true,
        projectDetails: true,
        targetAudience: true,
        websiteSpecifications: true,
        budgetTimeline: true,
        additionalServices: true,
      },
      loaderSave: false,
      // Data models
      client_name: "",
      contact_person: "",
      email: "",
      phone: "",
      current_website: "",
      project_name: "",
      project_description: "",
      project_objectives: "",
      target_audience_age: "",
      target_audience_gender: "",
      target_audience_location: "",
      target_audience_interests: "",
      website_type: "",
      website_sections: "",
      additional_features: "",
      design_preference: "",
      reference_websites: "",
      estimated_pages: null,
      budget: null,
      desired_start_date: "",
      desired_launch_date: "",
      needs_seo: false,
      needs_digital_marketing: false,
      requires_maintenance: false,
      content_provided_by_client: false,
      support_required: "",
      additional_requirements: "",

      // Error models
      error_client_name: "",
      error_contact_person: "",
      error_email: "",
      error_phone: "",
      error_current_website: "",
      error_project_name: "",
      error_project_description: "",
      error_project_objectives: "",
      error_target_audience_age: "",
      error_target_audience_gender: "",
      error_target_audience_location: "",
      error_target_audience_interests: "",
      error_website_type: "",
      error_estimated_pages: "",
      error_website_sections: "",
      error_additional_features: "",
      error_design_preference: "",
      error_reference_websites: "",
      error_budget: "",
      error_desired_start_date: "",
      error_desired_launch_date: "",
      error_needs_seo: "",
      error_needs_digital_marketing: "",
      error_requires_maintenance: "",
      error_content_provided_by_client: "",
      error_support_required: "",
      error_additional_requirements: "",
    };
  },
  methods: {
    errorToast() {
      this.toast.error("Hay campos requeridos", {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    successToast() {
      this.toast.success("Enviado", {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    ValidMessage() {
      this.errorMessage = "";
      this.errorMessage =
        this.message.length >= 255
          ? "El mensaje es muy largo solo se permiten 255 caracteres"
          : "";
    },
    validForm() {
      this.error_client_name = "";
      this.error_contact_person = "";
      this.error_email = "";
      this.error_phone = "";
      this.error_current_website = "";
      this.error_project_name = "";
      this.error_project_description = "";
      this.error_project_objectives = "";
      this.error_target_audience_age = "";
      this.error_target_audience_gender = "";
      this.error_target_audience_location = "";
      this.error_target_audience_interests = "";
      this.error_website_type = "";
      this.error_estimated_pages = "";
      this.error_website_sections = "";
      this.error_additional_features = "";
      this.error_design_preference = "";
      this.error_reference_websites = "";
      this.error_budget = "";
      this.error_desired_start_date = "";
      this.error_desired_launch_date = "";
      this.error_needs_seo = "";
      this.error_needs_digital_marketing = "";
      this.error_requires_maintenance = "";
      this.error_content_provided_by_client = "";
      this.error_support_required = "";
      this.error_additional_requirements = "";

      const validations = {
        client_name: "El nombre es requerido",
        contact_person: "El nombre de contacto es requerido",
        phone: "El celular es requerido",
        project_name: "El nombre del projecto es requerido",
        project_description: "La descripción es requerida",
        project_objectives: "Los objetivos son requeridos",
        target_audience_age: "Edad audiencia es requerido",
        target_audience_gender: "Genero audiencia es requerido",
        target_audience_location: "Locación audiencia es requerido",
        target_audience_interests: "Interes audiencia es requerido",
        website_type: "Tipo sitio web es requerido",
        website_sections: "Secciones son requeridas",
        additional_features: "Características adicionales es requerido",
        design_preference: "Preferenica del diseño es requerido",
        reference_websites: "Referencias es requerido",
        budget: "Presupuesto es requerido",
        estimated_pages: "Número de páginas es requerido",
        desired_start_date: "Fecha inicio es requerido",
        desired_launch_date: "Fecha lanzamiento es requerido",
        email: "E-mail inválido ej: name@example.com",
      };

      const validationKeys = Object.keys(validations);

      validationKeys.forEach((key) => {
        const value = this[key];
        const errorKey = `error_${key}`; // Now using underscore format for error variables
        this[errorKey] =
          value === "" ||
          value === null ||
          (typeof value === "string" && value.trim() === "")
            ? validations[key]
            : "";
      });

      if (
        !this.error_client_name &&
        !this.error_contact_person &&
        !this.error_phone &&
        !this.error_project_name &&
        !this.error_project_description &&
        !this.error_project_objectives &&
        !this.error_target_audience_age &&
        !this.error_target_audience_gender &&
        !this.error_target_audience_location &&
        !this.error_target_audience_interests &&
        !this.error_website_type &&
        !this.error_website_sections &&
        !this.error_additional_features &&
        !this.error_design_preference &&
        !this.error_budget &&
        !this.error_desired_start_date &&
        !this.error_desired_launch_date &&
        !this.error_email
      ) {
        this.sendRequest();
      } else {
        this.errorToast();
      }
    },
    validEmail() {
      var x = this.email; // Retrieve the email from the Vue instance
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(x)) {
        this.error_email = "E-mail inválido ej: name@example.com"; // Set the error message if the format is incorrect
        return false;
      }

      this.error_email = ""; // Clear the error message if the format is valid
      return true;
    },
    acceptNumber() {
      var x = this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async sendRequest() {
      try {
        this.loaderSave = true;
        const response = await apiClient.post("/requirements", {
          client_name: this.client_name,
          contact_person: this.contact_person,
          email: this.email,
          phone: this.phone,
          current_website: this.current_website,
          project_name: this.project_name,
          project_description: this.project_description,
          project_objectives: this.project_objectives,
          target_audience_age: this.target_audience_age,
          target_audience_gender: this.target_audience_gender,
          target_audience_location: this.target_audience_location,
          target_audience_interests: this.target_audience_interests,
          website_type: this.website_type,
          website_sections: this.website_sections,
          additional_features: this.additional_features,
          design_preference: this.design_preference,
          budget: this.budget,
          desired_start_date: this.desired_start_date,
          desired_launch_date: this.desired_launch_date,
          needs_seo: this.needs_seo,
          needs_digital_marketing: this.needs_digital_marketing,
          requires_maintenance: this.requires_maintenance,
          support_required: this.support_required,
          additional_requirements: this.additional_requirements,
          estimated_pages: this.estimated_pages,
          reference_websites: this.reference_websites,
          content_provided_by_client: this.content_provided_by_client,
        });
        if (response.status === 201) {
          // Clear variables
          this.client_name = "";
          this.contact_person = "";
          this.email = "";
          this.phone = "";
          this.current_website = "";
          this.project_name = "";
          this.project_description = "";
          this.project_objectives = "";
          this.target_audience_age = "";
          this.target_audience_gender = "";
          this.target_audience_location = "";
          this.target_audience_interests = "";
          this.website_type = "";
          this.website_sections = "";
          this.additional_features = "";
          this.design_preference = "";
          this.budget = null;
          this.desired_start_date = "";
          this.desired_launch_date = "";
          this.needs_seo = "";
          this.needs_digital_marketing = "";
          this.requires_maintenance = "";
          this.support_required = "";
          this.additional_requirements = "";
          this.estimated_pages = "";
          this.reference_websites = "";
          this.content_provided_by_client = "";

          // Send success notification
          this.successToast();
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
      this.loaderSave = false;
    },
  },
};
</script>

<style>
.custom-input {
  border: 1px solid #f5f5f5 !important; /* White border */
  background-color: transparent !important; /* No fill */
  color: #f5f5f5 !important; /* Text color, adjust as needed */
}

.custom-input:focus {
  border-color: #f5f5f5 !important; /* Maintain white border on focus */
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important; /* Optional: add a subtle focus shadow */
  background-color: transparent !important; /* Ensure no fill on focus */
  color: #f5f5f5 !important; /* Maintain text color on focus */
}

/* Optional: Style the label */
.form-label {
  color: #f5f5f5; /* Label color, adjust as needed */
}
.bs-gray-800 {
  background-color: #343a40;
}
.required::after {
  content: " *";
  color: #dc3545;
  font-weight: 800;
}
.custom-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23f5f5f5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
}
::placeholder {
  color: rgba(255, 255, 255, 0.25) !important;
}
</style>
