<template>
  <div id="app">
    <!-- <HomeView /> -->
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import HomeView from "./views/HomeView.vue";
import ProjectRequestView from "./views/ProjectRequestView.vue";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    // Views
    HomeView,
    ProjectRequestView,

    // components
    Navbar,
    Footer,
  },
};
</script>
